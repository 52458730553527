const Footer = () => {
    const CurrentYear = () => {
        return new Date().getFullYear();
    };
    return (
        <footer className="row py-3 mt-5">
            <div className="col-12 align-items-center text-end mt-5">
                <a href="https://kct.team" className="text-decoration-none text-body">
                    2023 &mdash; <CurrentYear /> &nbsp; kct.team
                </a>
            </div>
        </footer>
    );
};
export default Footer;
